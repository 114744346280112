import DirectusSDK from "@directus/sdk-js";
// main client instance
/*const client = new DirectusSDK({
  url: "https://demo-api.directus.app/",
});*/
const directus = new DirectusSDK({
  url: "https://rest.juicedupmedia.com/",
  project: "juicedupmedia",
  persist: true,
  storage: window.localStorage,
});
export default directus;
// define the loading states
/*export var ResponseState;
(function (ResponseState) {
  ResponseState["LOADING"] = "loading";
  ResponseState["ERRORED"] = "errored";
  ResponseState["SUCCESS"] = "success";
})(ResponseState || (ResponseState = {}));
export function useDirectus(fn, opts) {
  // save error and response state internally
  const [response, setResponse] = useState();
  const [error, setError] = useState();
  useEffect(() => {
    // will call the API with your provided fn
    const fetcher = async () => {
      try {
        const result = await fn(client);
        // set the response if everything's allright
        setResponse(result);
      } catch (err) {
        // set the error if something went wrong
        setError(err);
      }
    };
    // execute!
    fetcher();
  }, []);
  return [
    response,
    error,
    error
      ? ResponseState.ERRORED
      : response
      ? ResponseState.SUCCESS
      : ResponseState.LOADING,
  ];
}
*/
